import { Squares2X2Icon } from "@heroicons/react/24/outline";
import { CurrencyDollarIcon, UsersIcon } from "@heroicons/react/24/solid";
import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { Settings } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Element, Link } from "react-scroll";
import RecontributeEntries from "../../Components/dash/dashboard/RecontributeEntries";
import SiteStats from "../../Components/dash/dashboard/SiteStats";
import StatisticsChart from "../../Components/dash/dashboard/StatisticsChart";
import UserDownlines from "../../Components/dash/dashboard/UserDownlines";
import UserStats from "../../Components/dash/dashboard/UserStats";
import UpdateUsername from "../../Components/layout/UpdateUsername";
import { InputCauseDialog } from "../../Components/modals/InputCauseDialog";
import { useUserAuth } from "../../Contexts/authContext";
import { useAppDialog } from "../../Hooks/appDialog";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import {
  CommonCard,
  ContentLoading,
  TitlePrimary,
  TitleSecondary,
} from "../../Utility/Utility";
import { RecontributeDialog } from "../../Components/modals/RecontributeDialog";
import Anouncements from "../../Components/dash/dashboard/Anouncements";

const Dashboard = () => {

  const auth = useUserAuth();
  const helper = useHelpers();
  const navigate = useNavigate();
  const callAPI = useCallAPI();
  const appDialog = useAppDialog();
  const recontribAppDialog = useAppDialog();

  const [dash, setDash] = useState(false);

  useEffect(() => {
    if(auth.loggedIn) {
      getDashboard();
    }
  }, [auth.loggedIn]);

  const getDashboard = async () => {
    try {
      const res = await callAPI('user/dash');
      setDash(res);

      //Show popup if cause is not set
      res.userStats.cause === null && appDialog.open(res.userStats.cause);

    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <>
      <div className="mb-5 flex justify-between items-center">
        <TitlePrimary>Welcome 👋</TitlePrimary>
        {/* <div className="text-right hidden lg:block">
          <p className="font-bold leading-5 text-md text-gray-700">Global Entries</p>
          {dash && <div className="font-bold animate-pulse leading-7 text-2xl text-blue-400">{dash.siteStats[2].value}</div>}
        </div> */}
        <Link to="updateUsername" className="lg:hidden" smooth={true} duration={1000} offset={-100}><Button className="bg-blue-400 flex justify-between gap-2 items-center" size="sm"><Settings size={14} /> Update Username</Button></Link>
      </div>
      <Anouncements />
      {/* <div className="text-right flex justify-center border-t-[1px] pt-3 items-center gap-2 lg:hidden mb-4">
        <p className="font-bold leading-5 text-md text-gray-700">Global Entries</p>
        {dash && <div className="font-bold animate-pulse leading-7 text-2xl text-blue-400">{dash.siteStats[2].value}</div>}
      </div> */}
      {auth.loggedIn && dash ?
        <div className="grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-6 lg:gap-3">
          <CommonCard>
            <div className="bg-one px-4 py-3 text-white rounded-lg mb-7 cursor-pointer animate-pulse" onClick={() => navigate('/bonuspool')}>
              <h4 className="font-bold text-lg">New Bonus Pool</h4>
              <p className="leading-4 text-sm">New Month, New Bonus Pool is here. Refer friends and Win Big!</p>
            </div>
            <div className="text-left mb-5 animate-pulse">
              <TitleSecondary variant="light-orange">
                {dash.siteStats[0].value*1 - dash.userStats.user_id*1} People have united after you
              </TitleSecondary>
            </div>
            <div className="statistics bg-[#F4F4F4] rounded-[20px] p-3 mb-5 grid lg:grid-cols-2 gap-4 ">
              <div className="bg-[#fff] shadow-md rounded-[12px] p-4 grid grid-cols-[30px_auto] sm:grid-cols-[40px_auto] relative gap-4">
                <div onClick={() => navigate('/payments')} className="absolute cursor-pointer right-3 top-3 bg-blue-300 rounded-lg px-2 py-[5px] text-xs text-[#fff] inline-flex items-center justify-center">
                  <b className="font-bold mr-1"><helper.AmountToCurrency amount={dash.userStats.today_earnings} /></b>Today
                </div>
                <div className="pt-1">
                  <CurrencyDollarIcon className="text-blue-300 w-[45px]" />
                </div>
                <div className="text-left">
                  <p className="inline-flex items-center text-[#6F767E] font-semibold text-[13px] mb-[2px]">
                    My Total Earnings
                  </p>
                  <strong className="block text-[#1A1D1F] text-3xl md:text-4xl font-semibold">
                    <helper.AmountToCurrency amount={dash.userStats.total_earnings} />
                  </strong>
                </div>
              </div>
              <div className="bg-transparent rounded-[12px] p-4 grid grid-cols-[30px_auto] sm:grid-cols-[40px_auto] relative gap-4">
                <div className="absolute right-2 top-3 bg-[#FCFCFC] rounded-lg px-2 py-[5px] text-xs font-bold text-[#83BF6E] inline-flex items-center justify-center">
                  <b className="font-bold mr-1">{dash.userStats.referred_today}</b>Today
                </div>
                <div className="pt-1">
                  <UsersIcon className="text-green-300 w-[35px]" />
                </div>
                <div className="text-left">
                  <p className="inline-flex items-center text-[#6F767E] font-semibold text-[13px] mb-[2px]">
                    Total Referrals
                  </p>
                  <strong className="block text-[#1A1D1F] text-4xl md:text-5xl font-semibold">
                    {dash.userStats.total_referrals}
                  </strong>
                </div>
              </div>
            </div>
            <div className="graph">
              <StatisticsChart data={dash.graphData} />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-3 mt-3">
              <UserStats stats={dash.userStats} openCauseEdit={appDialog.open} />
              <UserDownlines downlines={dash.userDownline} />
            </div>
          </CommonCard>
          <div className="">
            <Element name="updateUsername"><UpdateUsername auth={auth} /></Element>
            <div className="bg-white rounded-10 shadow-md p-5 mt-3">
              <div className="flex justify-between items-center gap-2">
                <div className="">
                  <h4 className="text-green-400 text-4xl font-bold flex justify-start items-center gap-2">{dash.userStats.total_entries} {dash.userStats.total_entries*1 > 0 && <span className="bg-red-600 text-sm text-white px-3 py-1 rounded-10">{dash.userStats.total_expired_entries} Looped</span>}</h4>
                  <p className="text-gray-600 text-sm">Total Entries</p>
                  <Button onClick={() => navigate('/entries')} className="bg-gray-900 mt-3">View Entries</Button>
                </div>
                <Squares2X2Icon className="w-[70px] text-gray-300" />
              </div>
            </div>
            <div className="bg-white rounded-10 shadow-md p-5 mt-3">
              <div className="flex justify-between items-center gap-2">
                <div className="">
                  <h4 className="text-green-400 text-4xl font-bold"><helper.AmountToCurrency amount={auth?.appData.user.balance} /></h4>
                  <p className="text-gray-600 text-sm">Unclaimed Donations</p>
                  <Button onClick={() => navigate('/withdraw')} className="bg-green-400 mt-3">Claim Now</Button>
                </div>
                <CurrencyDollarIcon className="w-[70px] text-gray-300" />
              </div>
            </div>
            <RecontributeEntries showPopup={recontribAppDialog} />
            <div className="mt-4">
              <SiteStats siteStats={dash.siteStats} />
              {(dash.userStats.user_id*1 === 1 || dash.userStats.user_id*1 === 3) &&
                <CommonCard>
                  <div className="flex justify-between">
                    <p>Entry being filled</p>
                    {auth?.appData.entryToFill}
                  </div>
                </CommonCard>
              }
            </div>
          </div>
        </div>
      :
        <ContentLoading className='flex justify-center mt-[100px]' />
      }
      <InputCauseDialog hook={appDialog} />
      <RecontributeDialog hook={recontribAppDialog} />
    </>
  );
};
export default Dashboard;
