import Countdown from "react-countdown";
import { ArrowLeft } from "react-feather";
import { Link } from "react-router-dom";

const Newunitypro = () => {
  
  return (
    <>
      <div className="text-left bg-amber-400 rounded-xl px-5 py-4 text-gray-900 shadow-md">
          <div className="bg-gray-900 inline-block px-4 py-3 rounded-lg">
              <img src="https://i.ibb.co/s1fR7dy/PRO-LOGO.png" alt="logo" className="w-[150px] mx-auto" />
          </div>
          <h2 className="text-xl mb-2 mt-4 font-bold text-gray-900">Introducing NEWUNITY PRO</h2>
          <p className="text-md leading-5 mb-4">Have you thought of making money just by watching 10-seconds advertisement videos? Well, if you've ever encountered something like that elsewhere, then get ready to see New Unity's advanced version, which is truly mind-boggling. This is another income generator for many users of the New Unity platform.</p>
          <p className="text-md leading-5 mb-4">As we crowdfund to achieve our financial dreams and goals, we are about to earn remarkably from advertisements. New Unity has just secured a brilliant opportunity and deal with one-giant advertisement company. This is going to produce massive results.</p>
          <div className="text-lg font-bold text-gray-900 bg-white shadow-md px-3 py-2 rounded-lg leading-6 inline-block animate-pulse">Newunity PRO is launching in <br/><Countdown date={1725080400*1000} renderer={({ days, hours, minutes, seconds, completed }) => {
  return <span>{days} days and {hours} hours</span>;
}} /></div>
          <div className="pt-5 flex gap-3"> 
              <a href='https://t.me/newunityio/2899' target="blank">
                  <div className="text-sm mb-3 bg-green-900 text-white rounded-10 px-5 py-4 font-bold">
                      View Complan PDF
                  </div>
              </a>
              <a href='https://t.me/newunityio/2870' target="blank">
                  <div className="text-sm mb-3 bg-blue-800 text-white rounded-10 px-5 py-4 font-bold">
                      Watch Complan Video
                  </div>
              </a>
          </div>
      </div>
      <Link to={'/dashboard'}><p className="text-gray-700 mt-4 text-sm flex items-center gap-2"><ArrowLeft size={15} />Go to Newunity dashboard</p></Link>
    </>
  );
};
export default Newunitypro;
